<template>
  <div class="container logout-page">
    <div class="text-center space-3">
      <router-link to="/">
        <img class="logo" src="@/assets/img/brand/logo.svg" alt="trbo.io logo" />
      </router-link>
      <div class="description shadow-sm">
        <div class="pitch">
          <p v-if="$route.query.timeout === '1'">
            You have been automatically logged out due to inactivity.
          </p>
          <p v-else>
            You have been successfully logged out!
          </p>
          <p class="center aligned">
            <router-link style="width: 250px;"
                         class="btn btn-primary" to="/">
              Log back in again!
              <i class="fa fa-rocket fa-fw"></i>
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  beforeCreate() {
    if (this.$route.query.timeout !== '1') {
      setTimeout(() => {
        window.location.href = '/';
      }, 2000);
    }
  },
};
</script>
